<template>
  <div class="container" v-if="initOk">
    <div v-if="isSucceed" class="pay-tip">
      <van-icon class="icon" name="passed" />
      <p>支付成功</p>
      <van-button
        v-if="fee.unpaid > 0"
        type="default"
        block
        @click="continuePay()"
        >继续支付</van-button
      >
      <div style="margin-top: 10px">
        <van-button type="danger" block @click="navTo('/checkin')"
          >返回新生报到</van-button
        >
      </div>
    </div>
    <div v-else>
      <van-divider
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        在线缴费
      </van-divider>
      <van-form @submit="onSubmit">
        <van-panel title="" desc="支付金额（元）">
          <div>
            <van-field
              v-model="amount"
              type="digit"
              name="amount"
              label="￥"
              placeholder="支付金额"
              label-width="20px"
              size="large"
              :border="false"
              :readonly="url != undefined ? true : false"
              :rules="[{ required: true, message: '请输入您此次支付的金额' }]"
            />
            <van-notice-bar
              left-icon="info-o"
              text="可修改此次支付金额、支持多批次缴费"
            />
          </div>
        </van-panel>

        <div class="fee-info">
          <van-row>
            <van-col span="12"
              >学费总计: <b>￥{{ fee.amount }}</b></van-col
            >
            <van-col span="12"
              >已缴学费: <b>￥{{ fee.completed }}</b></van-col
            >
            <van-col span="12"
              >绿色通道: <b>-￥{{ fee.defer }}</b></van-col
            >
            <van-col span="12"
              >待缴学费: <b>￥{{ fee.unpaid }}</b></van-col
            >
          </van-row>
        </div>
        <div v-if="url" style="margin-top: 10px">
          <van-divider
            :style="{
              borderColor: '#ee0a24',
              padding: '0 100px',
            }"
          >
            请扫一扫支付
          </van-divider>
          <div class="qr">
            <vue-qr :text="url" :size="200"></vue-qr>
          </div>
        </div>
        <div v-else style="margin-top: 10px">
          <van-radio-group v-model="paymentMethod">
            <van-cell-group>
              <van-cell
                title="微信支付"
                label="推荐使用微信支付"
                clickable
                @click="paymentMethod = 'WECHAT'"
              >
                <template #icon>
                  <van-icon name="wechat" class="wechat-icon" />
                </template>
                <template #right-icon>
                  <van-radio name="WECHAT" />
                </template>
              </van-cell>
              <van-cell
                title="微信扫码支付"
                label="请您的亲友扫码支付"
                clickable
                @click="paymentMethod = 'WECHAT_CODE'"
              >
                <template #icon>
                  <van-icon name="qr" class="wechat-icon" />
                </template>
                <template #right-icon>
                  <van-radio name="WECHAT_CODE" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
          <div style="margin-top: 10px">
            <van-button
              block
              type="default"
              native-type="button"
              @click="navTo('/checkin/greenFee')"
              >绿色缴费通道申请</van-button
            >
          </div>
          <div style="margin-top: 10px">
            <van-button block type="danger" native-type="submit"
              >马上支付</van-button
            >
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import { Toast } from "vant";
import * as api from "@/apis/api";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      initOk: false,
      isSucceed: false,
      amount: 0,
      paymentMethod: "WECHAT",
      fee: {
        amount: 0,
        completed: 0,
        defer: 0,
        unpaid: 0,
        tuitions: [],
      },
      //微信支付
      payment: {},
      url: undefined,
      checkInterval: undefined,
    };
  },
  created() {
    this.findFee();
  },
  destroyed() {
    if (this.checkInterval) {
      window.clearInterval(this.checkInterval);
    }
  },
  methods: {
    navTo(url) {
      this.$router.push(url);
    },
    findFee() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.fee().then((res) => {
        this.fee = res;
        this.amount = this.fee.unpaid;
        Toast.clear();
        this.initOk = true;
      });
    },
    isWx() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    onBridgeReady(data) {
      let _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId,
          timeStamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: data.nonceStr, // 支付签名随机串，不长于 32
          package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: data.paySign, // 支付签名
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            _this.isSucceed = true;
            _this.findFee();
          }
        }
      );
    },
    onSubmit(values) {
      let _this = this;
      values.paymentMethod = _this.paymentMethod;
      values.openId = localStorage.getItem("openId");
      api.feePay(values).then((res) => {
        if (res.state == "ok") {
          if (_this.paymentMethod == "WECHAT") {
            
            if (!this.isWx()) {
              Notify({
                type: "danger",
                message: "请在微信内使用，或换一种支付方式",
              });
              return;
            }

            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  this.onBridgeReady(res.msg),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  this.onBridgeReady(res.msg)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  this.onBridgeReady(res.msg)
                );
              }
            } else {
              this.onBridgeReady(res.msg);
            }
          } else {
            //微信扫码
            _this.payment = res.msg.payment;
            _this.url = res.msg.url;
          }
          let checkInterval = window.setInterval(() => {
            api.feeCheck({ sn: _this.payment.sn }).then((res) => {
              if (res.state == "ok") {
                _this.isSucceed = true;
                _this.findFee();
                window.clearInterval(checkInterval);
              }
            });
          }, 1000);
          this.checkInterval = checkInterval;
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
    continuePay() {
      this.isSucceed = false;
      this.payment = {};
      this.url = undefined;
    },
  },
};
</script>
<style scoped>
.container {
  padding: 10px;
}
.fee-info {
  margin-top: 5px;
  font-size: 14px;
  background-color: #fff;
  padding: 10px;
  color: #555;
  font-size: 12px;
}
.fee-info .van-col {
  padding: 5px 0;
}
.fee-info b {
  color: #c12b35;
  font-weight: normal;
}
.wechat-icon {
  font-size: 40px;
  color: #1989fa;
  margin-right: 10px;
}
.pay-tip {
  text-align: center;
  margin: 100px 10px 10px 10px;
}
.pay-tip .icon {
  font-size: 60px;
  color: #08a611;
}
.pay-tip p {
  margin-bottom: 20px;
  font-size: 14px;
}
.van-cell--large {
  font-size: 20px !important;
}
.qr {
  padding: 0px 20px 60px 20px;
  text-align: center;
}
</style>
