var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.initOk)?_c('div',{staticClass:"container"},[(_vm.isSucceed)?_c('div',{staticClass:"pay-tip"},[_c('van-icon',{staticClass:"icon",attrs:{"name":"passed"}}),_c('p',[_vm._v("支付成功")]),(_vm.fee.unpaid > 0)?_c('van-button',{attrs:{"type":"default","block":""},on:{"click":function($event){return _vm.continuePay()}}},[_vm._v("继续支付")]):_vm._e(),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('van-button',{attrs:{"type":"danger","block":""},on:{"click":function($event){return _vm.navTo('/checkin')}}},[_vm._v("返回新生报到")])],1)],1):_c('div',[_c('van-divider',{style:({
        borderColor: '#ee0a24',
        padding: '0 100px',
      })},[_vm._v(" 在线缴费 ")]),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-panel',{attrs:{"title":"","desc":"支付金额（元）"}},[_c('div',[_c('van-field',{attrs:{"type":"digit","name":"amount","label":"￥","placeholder":"支付金额","label-width":"20px","size":"large","border":false,"readonly":_vm.url != undefined ? true : false,"rules":[{ required: true, message: '请输入您此次支付的金额' }]},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('van-notice-bar',{attrs:{"left-icon":"info-o","text":"可修改此次支付金额、支持多批次缴费"}})],1)]),_c('div',{staticClass:"fee-info"},[_c('van-row',[_c('van-col',{attrs:{"span":"12"}},[_vm._v("学费总计: "),_c('b',[_vm._v("￥"+_vm._s(_vm.fee.amount))])]),_c('van-col',{attrs:{"span":"12"}},[_vm._v("已缴学费: "),_c('b',[_vm._v("￥"+_vm._s(_vm.fee.completed))])]),_c('van-col',{attrs:{"span":"12"}},[_vm._v("绿色通道: "),_c('b',[_vm._v("-￥"+_vm._s(_vm.fee.defer))])]),_c('van-col',{attrs:{"span":"12"}},[_vm._v("待缴学费: "),_c('b',[_vm._v("￥"+_vm._s(_vm.fee.unpaid))])])],1)],1),(_vm.url)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('van-divider',{style:({
            borderColor: '#ee0a24',
            padding: '0 100px',
          })},[_vm._v(" 请扫一扫支付 ")]),_c('div',{staticClass:"qr"},[_c('vue-qr',{attrs:{"text":_vm.url,"size":200}})],1)],1):_c('div',{staticStyle:{"margin-top":"10px"}},[_c('van-radio-group',{model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}},[_c('van-cell-group',[_c('van-cell',{attrs:{"title":"微信支付","label":"推荐使用微信支付","clickable":""},on:{"click":function($event){_vm.paymentMethod = 'WECHAT'}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-icon',{staticClass:"wechat-icon",attrs:{"name":"wechat"}})]},proxy:true},{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"name":"WECHAT"}})]},proxy:true}],null,false,1480631444)}),_c('van-cell',{attrs:{"title":"微信扫码支付","label":"请您的亲友扫码支付","clickable":""},on:{"click":function($event){_vm.paymentMethod = 'WECHAT_CODE'}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-icon',{staticClass:"wechat-icon",attrs:{"name":"qr"}})]},proxy:true},{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"name":"WECHAT_CODE"}})]},proxy:true}],null,false,2933574313)})],1)],1),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('van-button',{attrs:{"block":"","type":"default","native-type":"button"},on:{"click":function($event){return _vm.navTo('/checkin/greenFee')}}},[_vm._v("绿色缴费通道申请")])],1),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('van-button',{attrs:{"block":"","type":"danger","native-type":"submit"}},[_vm._v("马上支付")])],1)],1)],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }